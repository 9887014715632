




import { readHasNurseAccess, readHasPhysicianAccess } from '@/store/main/getters';
import { Component, Vue } from 'vue-property-decorator';
import { store } from '@/store';


const routerGuardPhysician = async (to, from, next) => {
  if (!readHasPhysicianAccess(store) && !readHasNurseAccess(store)) {
    next('/main');
  } else {
    // only allow requisitions to physicians
    if (to.name.includes('requisition') && !readHasPhysicianAccess(store)) {
      next('/main');
    } else {
      next();
    }
  }
};

@Component
export default class Physician extends Vue {
  public beforeRouteEnter(to, from, next) {
    routerGuardPhysician(to, from, next);
  }

  public beforeRouteUpdate(to, from, next) {
    routerGuardPhysician(to, from, next);
  }
}
